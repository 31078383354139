
import Skeleton from '@/components/Skeleton.vue';
import {
  LossSeverityRiskGroup,
  LossSeverityModule,
  WeightedNPVItem,
  LossSeverityUnit,
  lossSeverityUnitOrder,
  getRawValueByUnit,
  LossSeverityPlotItem,
} from '@/store/modules/company-tabs/loss-severity';

import { Component, Vue, Prop } from 'vue-property-decorator';
import { formatCurrencyV2, formatNumber, getWeightedNpvId } from '../utils';

type TableItem = Record<LossSeverityRiskGroup, WeightedNPVItem | undefined> & {
  title: string;
  endingPoint?: WeightedNPVItem;
  attachmentPoint?: WeightedNPVItem;
  limit?: WeightedNPVItem;
};

@Component({ components: { Skeleton } })
export default class LossSeverityTableLight extends Vue {
  @Prop() selectedColumn!: LossSeverityRiskGroup;
  @Prop() severityRange!: [number, number];
  @Prop() retainedRiskInd!: [number, number];
  @Prop() transferableRiskInd!: [number, number];
  @Prop() totalRiskInd!: [number, number];

  get riskList() {
    return Object.values(LossSeverityRiskGroup).map((value) => ({
      value,
      text: value,
    }));
  }

  get currentPlotData() {
    return LossSeverityModule.currentPlot || [];
  }

  get weightedNpv() {
    const companyId = this.$store.getters.companyId;
    return [
      LossSeverityModule.weightedNpvMap.find(
        ({ id }) => id === getWeightedNpvId(companyId, this.retainedRiskInd)
      )?.data,
      LossSeverityModule.weightedNpvMap.find(
        ({ id }) => id === getWeightedNpvId(companyId, this.transferableRiskInd)
      )?.data,
      LossSeverityModule.weightedNpvMap.find(
        ({ id }) => id === getWeightedNpvId(companyId, this.totalRiskInd)
      )?.data,
    ];
  }

  get tableData() {
    const res: TableItem[] = [];
    const titles = ['Retained risk', 'Transferable risk'];

    this.severityRange.forEach((_el, ind) => {
      const item = this.riskList.reduce((pre, risk) => {
        const plotItem = LossSeverityModule.plots[risk.value];
        const attachmentPoint =
          plotItem && this.getWeightedNPVItem(plotItem[this.retainedRiskInd[ind]]);
        const endingPoint =
          plotItem && this.getWeightedNPVItem(plotItem[this.transferableRiskInd[ind]]);
        const limit =
          endingPoint &&
          attachmentPoint &&
          this.sumWeightedNPVItem(endingPoint, attachmentPoint, true);

        const value = this.weightedNpv[ind]?.[risk.value];

        return {
          ...pre,
          [risk.value]: value && {
            ...value,
            WeightedNPVRaw: value.WeightedNPVRaw * LossSeverityModule.coefficient,
            WeightedNPV: value.WeightedNPV * LossSeverityModule.coefficient,
          },
          endingPoint,
          attachmentPoint,
          limit,
        };
      }, {} as TableItem);
      item.title = titles[ind];
      res.push(item);
    });

    const total = this.riskList.reduce((pre, risk) => {
      if (res.some((el) => el[risk.value] === undefined))
        return {
          ...pre,
          [risk.value]: undefined,
        };
      const sum = this.weightedNpv[2]?.[risk.value];
      return {
        ...pre,
        [risk.value]: sum && {
          ...sum,
          WeightedNPVRaw: sum.WeightedNPVRaw * LossSeverityModule.coefficient,
          WeightedNPV: sum.WeightedNPV * LossSeverityModule.coefficient,
        },
      };
    }, {} as TableItem);
    total.title = 'total';
    total.attachmentPoint = this.getWeightedNPVItem(this.currentPlotData[this.totalRiskInd[0]]);
    total.endingPoint = this.getWeightedNPVItem(this.currentPlotData[this.totalRiskInd[1]]);
    total.limit =
      total.endingPoint &&
      total.attachmentPoint &&
      this.sumWeightedNPVItem(total.endingPoint, total.attachmentPoint, true);
    res.push(total);
    return res;
  }

  _formatCurrencyV2(value: number, unit?: LossSeverityUnit) {
    return formatCurrencyV2(value, LossSeverityModule.currency, unit);
  }

  _formatNumber(value: number) {
    return formatNumber(value);
  }

  getWeightedNPVItem({ x, xRaw, xUnit }: LossSeverityPlotItem): WeightedNPVItem {
    return {
      WeightedNPV: x,
      WeightedNPVRaw: xRaw,
      Unit: xUnit,
    };
  }

  sumWeightedNPVItem(a: WeightedNPVItem, b: WeightedNPVItem, negative?: boolean): WeightedNPVItem {
    const unit =
      lossSeverityUnitOrder.find((unit) => unit === a.Unit || unit === b.Unit) ||
      LossSeverityUnit.none;
    const rawSum = negative
      ? a.WeightedNPVRaw - b.WeightedNPVRaw
      : a.WeightedNPVRaw + b.WeightedNPVRaw;
    const del = getRawValueByUnit(1, unit);
    return {
      Unit: unit,
      WeightedNPVRaw: rawSum,
      WeightedNPV: rawSum / del,
    };
  }
}
